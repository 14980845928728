<template>
	<div class="view">
		<div class="main text-center">
			<b-row class="title">
				<b-col>새 비밀번호 입력</b-col>
			</b-row>

			<b-form-group id="password1" invalid-feedback="비밀번호는 영문과 숫자를 포함하여 10자 이상으로 입력해주세요." :state="password1_empty || password1_validation">
				<b-form-input class="input" type="password" v-model="password1" placeholder="비밀번호" debounce="300" />
			</b-form-group>

			<b-form-group id="password2" invalid-feedback="비밀번호가 일치하지 않습니다." :state="password2_empty || password2_validation">
				<b-form-input class="input" v-model="password2" placeholder="비밀번호 재입력" type="password" debounce="300" />
			</b-form-group>

			<b-button block v-if="!form_validation || submitted" disabled> 다음 </b-button>
			<b-button block v-if="submitting && !submitted" disabled><b-spinner></b-spinner></b-button>
			<b-button class="orange" block v-if="form_validation && !submitting && !submitted" type="submit" v-on:click="makePasswordResetConfirm"> 다음 </b-button>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
export default {
	name: 'PasswordResetConfirm',
	metaInfo: {
		// 페이지 제목 설정
		title: '새 비밀번호 입력',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
		],
	},
	data() {
		return {
			// 회원가입 1단계 데이터
			password1: '',
			password2: '',
			password1_feedback: '',
			password2_feedback: '',
			submitting: false,
			submitted: false,
		};
	},
	computed: {
		// 비밀번호 영문, 숫자, 10글자 이상 검사
		password1_validation() {
			var reg = /^.*(?=.{10,64})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
			if (reg.test(this.password1)) {
				return true;
			}
			this.password1_feedback = '비밀번호는 영문과 숫자를 포함하여 10자 이상으로 입력해주세요.';
			return false;
		},
		// 비밀번호 일치하는지 검사
		password2_validation() {
			if (this.password2.length > 0) {
				if (this.password1 === this.password2) {
					return true;
				}
			}
			this.password2_feedback = '비밀번호가 일치하지 않습니다.';
			return false;
		},
		password1_empty() {
			return this.password1.length === 0;
		},
		password2_empty() {
			return this.password2.length === 0;
		},
		form_validation() {
			return this.password1_validation && this.password2_validation;
		},
	},
	props: ['uid', 'reset_token'],
	methods: {
		// 회원가입 요청
		makePasswordResetConfirm() {
			this.submitting = true;
			// actions 메소드 호출
			this.$store
				.dispatch('PASSWORD_RESET_CONFIRM', {
					new_password: this.password1,
					re_new_password: this.password2,
					uid: this.uid,
					token: this.reset_token,
				})
				.then((res) => {
					// 서버 응답이 정상일 경우
					if (this.$store.state.serverStatus === 204) {
						this.submitted = true;
						this.submitting = false;
						alert('비밀번호가 성공적으로 변경되었습니다. 다시 로그인해주세요.');
						this.$router.push('/login').catch((err) => {});
					}
					// 요청 실패
					if (this.$store.state.serverStatus === 400) {
						this.submitting = false;
						console.log('error during password reset confirm');
					}
				});
		},
	},
	components: {},
};
</script>

<style scoped>
.main {
	padding-top: 25vh;
}

.title {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 2rem;
}
.card {
	margin-bottom: 1em;
}
.card,
.invalid-feedback {
	font-size: 90%;
}
.btn {
	margin: auto;
}

@media (min-width: 992px) {
	.main {
		margin: auto;
		width: 50%;
	}
}
</style>
